import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared';
// import { HomeComponent } from './home/home.component';

const routes: Routes = [
    // { path: '', component: HomeComponent },
     { path: '', loadChildren: './home/home.module#HomeModule'  },
     { path: 'landing', loadChildren: './home/home.module#HomeModule'  },
    // { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'parents', loadChildren: './layoutParent/layoutParent.module#LayoutParentModule', canActivate: [AuthGuard] },
    { path: 'layoutForAdmin', loadChildren: './layout/layout.module#LayoutModule', canActivate: [AuthGuard] },
    { path: 'layout', loadChildren: './layoutParent/layoutParent.module#LayoutParentModule', canActivate: [AuthGuard] },
    { path: 'login', loadChildren: './login/login.module#LoginModule' },
    { path: 'signup', loadChildren: './signup/signup.module#SignupModule' },
    { path: 'error', loadChildren: './server-error/server-error.module#ServerErrorModule' },
    { path: 'access-denied', loadChildren: './access-denied/access-denied.module#AccessDeniedModule' },
    { path: 'not-found', loadChildren: './not-found/not-found.module#NotFoundModule' },
    { path: '**', redirectTo: 'landing' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes )],
    exports: [RouterModule]
})
export class AppRoutingModule {}
