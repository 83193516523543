import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { AutheService } from './authe.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UserService {

 headers = new HttpHeaders({
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${localStorage.getItem('token')}`
});

  baseUrl =  environment.apiUrl ;
  constructor(private http: HttpClient , private router: Router) { }

  updateUser(model: any) {
    return this.http.post(this.baseUrl + 'auth/register' , model , {headers: new HttpHeaders()
    .set('Content-Type', 'application/json')});
  }
  //  ,{ headers: this.headers}
  getUser(id): Observable<User> {
    return this.http.get<User>( this.baseUrl + 'user/' + id);
  }

}
