import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
// import { HttpModule } from '@angular/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard, SharedPipesModule } from './shared';
import { AutheService } from './_services/authe.service';

import { JwtModule } from '@auth0/angular-jwt';
import { UserResolver } from './_resolvers/users.resolver';
import { DataTableModule } from 'angular-6-datatable';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { DateformatPipe } from './shared/pipes/dateformat.pipe';
import { SafeHtmlPipe } from './shared/pipes/SafeHtml.pipe';

// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
    /* for development
    return new TranslateHttpLoader(
        http,
        '/start-angular/SB-Admin-BS4-Angular-6/master/dist/assets/i18n/',
        '.json'
    ); */
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};
export function tokenGetter() {
    return localStorage.getItem('token');
  }
@NgModule({
   imports: [
      CommonModule,
      BrowserModule,
      BrowserAnimationsModule,
      HttpClientModule,
      // HttpModule,
      TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: createTranslateLoader,
            deps: [HttpClient]
        }
    }),
    JwtModule.forRoot({
        config: {
            tokenGetter: tokenGetter,
            whitelistedDomains: ['localhost:54692'],
            blacklistedRoutes: ['localhost:54692/api/auth']
        }
    }),
    NgxLoadingModule.forRoot({
        animationType: ngxLoadingAnimationTypes.chasingDots,
    }),
    DataTableModule,
    AppRoutingModule
],
    declarations: [AppComponent],
    providers: [AuthGuard, AutheService, UserResolver, DateformatPipe, SafeHtmlPipe],
    bootstrap: [AppComponent]
})
export class AppModule {}
