import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer , SafeHtml} from '@angular/platform-browser';

@Pipe({
  name: 'SafeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  public transform(value: any, type: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

}

