import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateformatPipe } from './dateformat.pipe';
import { SafeHtmlPipe } from './SafeHtml.pipe';

@NgModule({
   imports: [
      CommonModule
   ],
   declarations: [
       DateformatPipe, SafeHtmlPipe
   ],
   exports: [
      DateformatPipe, SafeHtmlPipe
  ]
})
export class SharedPipesModule { }
