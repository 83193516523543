import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AutheService } from './_services/authe.service';
import { User } from './models/user';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    constructor( private authService: AutheService, private jwtHelperService: JwtHelperService) {
    }

    ngOnInit() {
        // const token = localStorage.getItem('token');
        // const user: User = JSON.parse(localStorage.getItem('user'));
        // if ( token) {
        //     this.authService.decodedToken = this.jwtHelperService.decodeToken(token);
        // }
        // if (user) {
        //     this.authService.currentUser = user;
        // }
    }
}
